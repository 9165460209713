<template>
  <div class="login-body">
    <div class="we-login">
      <div class="login-logo">
        <div class="pt10">松屋银座小助手管理系统</div>
      </div>
      <div class="tc pb40 pt20">
        <el-form :model="form" label-width="0px">
          <el-form-item label="">
            <el-input v-model="form.username" placeholder="用户名" @keydown.enter="login"/>
          </el-form-item>
          <el-form-item label="">
            <el-input
              v-model="form.password"
              type="password"
              show-password
              placeholder="密码"
              @keydown.enter="login"
            />
          </el-form-item>
          <el-form-item label-width="0px">
            <div class="login-btn">
              <el-button type="primary" @click="login">登录</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive } from '@vue/reactivity'
import { authApi } from '@/api'
import { ElMessage } from 'element-plus'
import { useRoute, useRouter } from 'vue-router'

export default {
  setup() {
    const route = useRoute()
    const router = useRouter()

    const form = reactive({
      username: '',
      password: '',
    })
    const login = () => {
      if (!form.username) {
        ElMessage.error('请输入用户名')
        return
      }
      if (!form.password) {
        ElMessage.error('请输入密码')
        return
      }
      const authorization = btoa(form.username + ':' + form.password)
      // 缓存到 sessionStorage
      sessionStorage.setItem('authorization', authorization)

      authApi.login().then(() => {
        router.replace({
          name: 'MerchantList'
        })
      })
    }
    return {
      form,
      login,
    }
  },
}
</script>
<style scoped>
.login-body {
  width: 100%;
  height: 100%;
  /* background: linear-gradient(to left, #536976, #292e49); */
  /* background: linear-gradient(320deg, #f27121, #e77a6e, #e78a7f); */
  /* 蓝色渐变 */
  background-image: linear-gradient(135deg, #ABDCFF 10%, #0396FF 100%);
}

.we-login {
  width: 300px;
  padding: 15px 45px 15px 45px;
  border-radius: 8px;
  color: #ffffff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  margin: auto;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.login-logo {
  margin: 50px auto 40px;
  text-align: center;
}

.login-logo img {
  width: 90px;
  height: auto;
}

.login-btn {
  width: 70%;
  margin: 20px auto 0 auto;
}
</style>
<style>
.login-btn .el-button {
  width: 100%;
}

.login-body .el-form-item__label {
  color: #ffffff;
}

.login-body .el-input__wrapper {
  background: rgba(255, 255, 255, 0.8);
}
</style>
